import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "./store";

import * as Sentry from "@sentry/react";

// Sentry 초기화
Sentry.init({
  dsn: "https://b230bcfae5013fc01b165200399bfeeb@o4508392024309760.ingest.us.sentry.io/4508392025751552",
  integrations: [
    Sentry.browserTracingIntegration(), // 브라우저 트레이싱 통합
    Sentry.replayIntegration(), // 세션 리플레이 통합
  ],
  // Tracing 설정
  tracesSampleRate: 1.0, // 100% 트랜잭션 수집
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/], // 트레이싱 활성화 대상
  // 세션 리플레이 설정
  replaysSessionSampleRate: 0.1, // 세션 샘플링 10%
  replaysOnErrorSampleRate: 1.0, // 오류 발생 시 세션 100% 샘플링
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
