export interface PurchaseProduct {
  name: string;
  price: number;
  vat: number;
  type: "one-time";
}

export const product_slot_5000: PurchaseProduct = {
  name: "단어 슬롯 5,000칸 확장",
  price: 9900,
  vat: 990,
  type: "one-time",
};
