import Group from "../groups/Group";
import { TermLevel } from "../term/TermLevel";
import { PronunciationPosition } from "./PronunciationPosition";
import { QuestionType } from "./QuestionType";
import { SortType } from "./SortType";

const learningSelectedGroupsKey = "LearningSelectedGroupsKey";
const learningSelectedLevlesKey = "LearningSelectedLevelsKey";
const learningSortTypeKey = "LearningSortTypeKey";
const learningQuestionTypeKey = "LearningQuestionTypeKey";
const learningPronunciationPrositionKey = "LearningPronunciationPositionKey";
const learningTextToSpeechKey = "LearningTextToSpeechKey";
const learningWrongAnswerNoteKey = "LearningWrongAnswerNoteKey";
const learningIntervalUnitCountKey = "LearningIntervalUnitCountKey";

const spellingQuizQuestionType = "SpellingQuizQuestionTypeKey";

export function initializeLearningSettings() {
  localStorage.removeItem(learningSelectedGroupsKey);
  localStorage.removeItem(learningSelectedLevlesKey);
  localStorage.removeItem(learningSortTypeKey);
  localStorage.removeItem(learningQuestionTypeKey);
  localStorage.removeItem(learningPronunciationPrositionKey);
  localStorage.removeItem(learningTextToSpeechKey);
  localStorage.removeItem(learningWrongAnswerNoteKey);
  localStorage.removeItem(spellingQuizQuestionType);
}

export function getLearningIntervalUnitCountFromLocalStorage(): number {
  const storedCount = localStorage.getItem(learningIntervalUnitCountKey);
  return storedCount ? JSON.parse(storedCount) : 20;
}

export function setLearningIntervalUnitCountToLocalStroage(count: number) {
  localStorage.setItem(learningIntervalUnitCountKey, JSON.stringify(count));
}

export function getLearningSelectedGroupsFromLocalStorage(): Group[] {
  const storedGroups = localStorage.getItem(learningSelectedGroupsKey);
  if (storedGroups === null || storedGroups === undefined) {
    return [];
  } else {
    return JSON.parse(storedGroups);
  }
}

export function setLearningSelectedGroupsToLocalStroage(
  selectedGroups: Group[]
) {
  if (selectedGroups.length === 1 && selectedGroups[0].title === "all") {
    localStorage.setItem(learningSelectedGroupsKey, JSON.stringify([]));
  } else if (
    selectedGroups.length === 1 &&
    selectedGroups[0].title === "no_group"
  ) {
    const group = new Group("", 0, "", 0);
    localStorage.setItem(learningSelectedGroupsKey, JSON.stringify([group]));
  } else {
    localStorage.setItem(
      learningSelectedGroupsKey,
      JSON.stringify(selectedGroups)
    );
  }
}

export function getLearningSelectedLevelFromLocalStroage(): TermLevel[] {
  const storedLevels = localStorage.getItem(learningSelectedLevlesKey);
  if (storedLevels === null || storedLevels === undefined) {
    localStorage.setItem(
      learningSelectedLevlesKey,
      JSON.stringify([
        TermLevel.difficult,
        TermLevel.familiar,
        TermLevel.perfect,
      ])
    );
    return [TermLevel.difficult, TermLevel.familiar, TermLevel.perfect];
  } else {
    return JSON.parse(storedLevels);
  }
}

export function setLearningSelectedLevelsToLocalStroage(
  selectedLevels: TermLevel[]
) {
  localStorage.setItem(
    learningSelectedLevlesKey,
    JSON.stringify(selectedLevels)
  );
}

export function getLearningSortTypeFromLocalStorage(): SortType {
  if (localStorage.getItem(learningSortTypeKey) === "recent") {
    return SortType.recent;
  } else if (localStorage.getItem(learningSortTypeKey) === "old") {
    return SortType.old;
  } else if (localStorage.getItem(learningSortTypeKey) === "abc") {
    return SortType.abc;
  } else if (localStorage.getItem(learningSortTypeKey) === "random") {
    return SortType.random;
  } else {
    localStorage.setItem(learningSortTypeKey, "random");
    return SortType.random;
  }
}

export function setLearningSortTypeToLocalStorage(type: SortType) {
  switch (type) {
    case SortType.recent:
      localStorage.setItem(learningSortTypeKey, "recent");
      break;
    case SortType.old:
      localStorage.setItem(learningSortTypeKey, "old");
      break;
    case SortType.abc:
      localStorage.setItem(learningSortTypeKey, "abc");
      break;
    case SortType.random:
      localStorage.setItem(learningSortTypeKey, "random");
      break;
  }
}

export function getLearningQuestionTypeFromLocalStorage(): QuestionType {
  if (localStorage.getItem(learningQuestionTypeKey) === "termFirst") {
    return QuestionType.termFirst;
  } else if (localStorage.getItem(learningQuestionTypeKey) === "defiFirst") {
    return QuestionType.definitionFirst;
  } else if (localStorage.getItem(learningQuestionTypeKey) === "random") {
    return QuestionType.random;
  } else {
    localStorage.setItem(learningQuestionTypeKey, "termFirst");
    return QuestionType.termFirst;
  }
}

export function setLearningQuestionTypeToLocalStorage(type: QuestionType) {
  switch (type) {
    case QuestionType.termFirst:
      localStorage.setItem(learningQuestionTypeKey, "termFirst");
      break;
    case QuestionType.definitionFirst:
      localStorage.setItem(learningQuestionTypeKey, "defiFirst");
      break;
    case QuestionType.random:
      localStorage.setItem(learningQuestionTypeKey, "random");
      break;
  }
}

export function getSpellingQuizQuestionTypeFromLocalStorage(): QuestionType {
  if (localStorage.getItem(spellingQuizQuestionType) === "termFirst") {
    return QuestionType.termFirst;
  } else if (localStorage.getItem(spellingQuizQuestionType) === "defiFirst") {
    return QuestionType.definitionFirst;
  } else if (localStorage.getItem(spellingQuizQuestionType) === "random") {
    return QuestionType.random;
  } else {
    localStorage.setItem(spellingQuizQuestionType, "defiFirst");
    return QuestionType.definitionFirst;
  }
}

export function setSpellingQuizQuestionTypeToLocalStorage(type: QuestionType) {
  switch (type) {
    case QuestionType.termFirst:
      localStorage.setItem(spellingQuizQuestionType, "termFirst");
      break;
    case QuestionType.definitionFirst:
      localStorage.setItem(spellingQuizQuestionType, "defiFirst");
      break;
    case QuestionType.random:
      localStorage.setItem(spellingQuizQuestionType, "random");
      break;
  }
}

export function getLearningPronunciationPositionFromLocalStroage(): PronunciationPosition {
  if (localStorage.getItem(learningPronunciationPrositionKey) === "onTerm") {
    return PronunciationPosition.onTerm;
  } else if (
    localStorage.getItem(learningPronunciationPrositionKey) === "onDefi"
  ) {
    return PronunciationPosition.onDefinition;
  } else {
    localStorage.setItem(learningPronunciationPrositionKey, "onTerm");
    return PronunciationPosition.onTerm;
  }
}

export function setLearningPronunciationPositionToLocalStorage(
  position: PronunciationPosition
) {
  switch (position) {
    case PronunciationPosition.onTerm:
      localStorage.setItem(learningPronunciationPrositionKey, "onTerm");
      break;
    case PronunciationPosition.onDefinition:
      localStorage.setItem(learningPronunciationPrositionKey, "onDefi");
      break;
  }
}

export function isOnLearningTextToSpeechFromLocalStorage(): boolean {
  const value = localStorage.getItem(learningTextToSpeechKey);
  if (value !== null) {
    if (value === "on") {
      return true;
    } else {
      return false;
    }
  } else {
    setIsOnLearningTextToSpeechToLocalStorage(true);
    return true;
  }
}

export function setIsOnLearningTextToSpeechToLocalStorage(isOn: boolean) {
  const value = isOn ? "on" : "off";
  localStorage.setItem(learningTextToSpeechKey, value);
}

export function isOnLearningWrongAnswerNoteFromLocalStroage(): boolean {
  const value = localStorage.getItem(learningWrongAnswerNoteKey);
  if (value !== null) {
    if (value === "on") {
      return true;
    } else {
      return false;
    }
  } else {
    setIsOnLearningWrongAnswerNoteFromToLocalStroage(true);
    return true;
  }
}

export function setIsOnLearningWrongAnswerNoteFromToLocalStroage(
  isOn: boolean
) {
  const value = isOn ? "on" : "off";
  localStorage.setItem(learningWrongAnswerNoteKey, value);
}
