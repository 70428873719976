import styles from "./CountPicker.module.css";
import { useTranslation } from "react-i18next";
import { CountPickerStyle } from "./CountPickerStyle";
import { useEffect, useState } from "react";
import BigButton from "./BigButton";
import { setLearningIntervalUnitCountToLocalStroage } from "../settings/learningSettings";
import { NO_LIMIT } from "../learning/learning";
import {
  AddBoxRounded,
  IndeterminateCheckBoxRounded,
} from "@mui/icons-material";

interface CountPickerProps {
  open: boolean;
  defaultValue: number;
  style: CountPickerStyle;
  onComplete: (value: number) => void;
  onClose: () => void;
}

export default function CountPicker(props: CountPickerProps) {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [displayText, setDisplayText] = useState("");
  const [minimum, setMinimum] = useState(0);
  const [maximum, setMaximum] = useState(0);
  const [interval, setInterval] = useState(0);

  useEffect(() => {
    if (props.open === true) {
      const newValue = props.defaultValue;
      setCurrentValue(newValue);
      updateValueLimit();
    }
  }, [props.open]);

  useEffect(() => {
    updateDisplayText();
  }, [currentValue]);

  function updateValueLimit() {
    switch (props.style) {
      case CountPickerStyle.learningIntervalUnitCount:
        setMinimum(0);
        setMaximum(200);
        setInterval(10);
        break;
      case CountPickerStyle.learningGoalCount:
        break;
    }
  }

  function updateDisplayText() {
    switch (props.style) {
      case CountPickerStyle.learningIntervalUnitCount:
        if (currentValue == NO_LIMIT) {
          const newDisplayText = t("LearningIntervalUnitCountAllType");
          setDisplayText(newDisplayText);
        } else {
          const count = currentValue;
          const newDisplayText = t("LearningIntervalUnitCountValueType", {
            count,
          });
          setDisplayText(newDisplayText);
        }
        break;
      case CountPickerStyle.learningGoalCount:
        break;
    }
  }

  function complete() {
    props.onComplete(currentValue);
    props.onClose();
  }

  function handleOnClickMinusButton() {
    let newValue = currentValue - interval;
    if (newValue < minimum) {
      newValue = minimum;
    }
    setCurrentValue(newValue);
  }

  function handleOnClickPlusButton() {
    let newValue = currentValue + interval;
    if (newValue > maximum) {
      newValue = maximum;
    }
    setCurrentValue(newValue);
  }

  if (props.open === false) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.pickerContainer}>
          <p className={styles.displayText}>{displayText}</p>
          <div className={styles.stepperButtonsContainer}>
            <div onClick={() => handleOnClickMinusButton()}>
              <IndeterminateCheckBoxRounded
                className={styles.stepperButton}
                sx={{ color: "var(--dark-gray-color)" }}
              />
            </div>
            <div onClick={() => handleOnClickPlusButton()}>
              <AddBoxRounded
                className={styles.stepperButton}
                sx={{ color: "var(--dark-gray-color)" }}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <BigButton
              title={t("Cancel")}
              style="disable"
              handleClick={() => {
                props.onClose();
              }}
            />
            <BigButton
              title={t("Confirmation")}
              style="normal"
              handleClick={() => {
                complete();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
