import styles from "./Written.module.css";
import { useTranslation } from "react-i18next";
import AudioPlayer from "react-h5-audio-player";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Term from "../term/Term";
import SpeakButton from "../common/SpeakButton";
import { fetchGroupLanguageCode } from "../groups/groupAPI";
import { getVoiceFile, makeBase64String } from "../voice/voice";
import {
  getLearningPronunciationPositionFromLocalStroage,
  getSpellingQuizQuestionTypeFromLocalStorage,
  isOnLearningTextToSpeechFromLocalStorage,
} from "../settings/learningSettings";
import { PronunciationPosition } from "../settings/PronunciationPosition";
import { QuestionType } from "../settings/QuestionType";
import { auth } from "../../configurations/firebaseConfig";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import { delay } from "lodash";
import classNames from "classnames";
import { normalizeString } from "../common/strings";
import BigButton from "../common/BigButton";
import EnterKeyListenerComponent from "../common/keyListeners";

interface WrittenProps {
  term: Term;
  onCorrect: (term: Term) => void;
  onIncorrect: (term: Term) => void;
  onSpeakError: (error: string) => void;
}

export default function Written(props: WrittenProps) {
  const { t } = useTranslation();

  const [audioSrc, setAudioSrc] = useState<string>();
  const randomChoice = Math.random() < 0.5 ? "term" : "definition";
  const [currentQuestion, setCurrentQuestion] = useState<"term" | "definition">(
    getCurrentQuestionType()
  );

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [questionUpdateCount, setQuestionUpdateCount] = useState(0);
  const [currentGroup, setCurrentGroup] = useState("");
  const [currentGroupUpdateCount, setCurrentGroupUpdateCount] = useState(0);
  const [speakCount, setSpeakCount] = useState(0);
  const [enterKeyPressedCount, setEnterKeyPressedCount] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isResultMode, setIsResultMode] = useState(false);
  const [isAnswerResult, setIsAnswerResult] = useState(false);

  useEffect(() => {
    reload();
  }, [props.term]);

  useEffect(() => {
    setCurrentGroupUpdateCount(currentGroupUpdateCount + 1);
  }, [currentGroup]);

  useEffect(() => {
    setQuestionUpdateCount(questionUpdateCount + 1);
  }, [question]);

  useEffect(() => {
    if (
      speakCount === 0 &&
      currentGroupUpdateCount > 0 &&
      questionUpdateCount > 0 &&
      auth.currentUser !== null
    ) {
      if (isOnLearningTextToSpeechFromLocalStorage() === true) {
        speak();
      }
    }
  }, [question, currentGroup]);

  useEffect(() => {
    setQuestion(
      currentQuestion === "term" ? props.term.term : props.term.definition
    );
    setAnswer(
      currentQuestion === "term" ? props.term.definition : props.term.term
    );
    setCurrentGroup(currentQuestion === "term" ? props.term.group : "");
  }, [currentQuestion, props.term]);

  function handleOnClickSpeakButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    speak();
  }

  function isShowPronunciation(): boolean {
    if (
      currentQuestion === "term" &&
      getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onTerm &&
      props.term.memo.length !== 0
    ) {
      return true;
    } else if (
      currentQuestion === "definition" &&
      getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onDefinition &&
      props.term.memo.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  function reload() {
    setIsAnswerResult(false);
    setIsResultMode(false);
    setSpeakCount(0);
    updateCurrentQuestionType();
  }

  function getCurrentQuestionType(): "term" | "definition" {
    switch (getSpellingQuizQuestionTypeFromLocalStorage()) {
      case QuestionType.termFirst:
        return "term";
      case QuestionType.definitionFirst:
        return "definition";
      case QuestionType.random:
        return randomChoice;
    }
  }

  function updateCurrentQuestionType() {
    switch (getSpellingQuizQuestionTypeFromLocalStorage()) {
      case QuestionType.termFirst:
        setCurrentQuestion("term");
        break;
      case QuestionType.definitionFirst:
        setCurrentQuestion("definition");
        break;
      case QuestionType.random:
        setCurrentQuestion(randomChoice);
        break;
    }
  }

  function isShowMemo(): boolean {
    return (
      currentQuestion === "definition" && props.term.pronunciation.length !== 0
    );
  }

  function updateUserAnswer(answer: string) {
    setUserAnswer(answer);
  }

  function handleOnEnter() {
    const isAnswer = normalizeString(userAnswer) === normalizeString(answer);
    setIsResultMode(true);
    setIsAnswerResult(isAnswer);
    if (isAnswer) {
      delay(() => {
        props.onCorrect(props.term);
      }, 1000);
    }
  }

  function handleOnClickContinueButton() {
    props.onIncorrect(props.term);
  }

  async function speak() {
    setSpeakCount(speakCount + 1);
    setIsPlaying(true);
    try {
      const languageCode =
        currentGroup.length !== 0
          ? await fetchGroupLanguageCode(currentGroup)
          : "";
      const audio = await getVoiceFile(question, languageCode);
      setAudioSrc(makeBase64String(audio));
    } catch (error) {
      if (error instanceof Error) {
        props.onSpeakError(error.message);
      }
    }
  }

  const answerTitleTextClass = classNames(styles.yourAnswerTitle, {
    [styles.yourAnswerTitleCorrect]:
      isAnswerResult === true && isResultMode === true,
    [styles.yourAnswerTitleIncorrect]:
      isAnswerResult === false && isResultMode === true,
  });

  return (
    <>
      <motion.div
        className={styles.containerForMotion}
        key={props.term.term}
        initial={{ x: 100, opacity: 0 }}
        animate={{
          rotateX: 0,
          x: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
        style={{
          transformStyle: "preserve-3d",
        }}
      >
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <SpeakButton
              fontSize="small"
              isPlaying={isPlaying}
              onClick={handleOnClickSpeakButton}
            />
          </div>
          <div className={styles.contentsContainer}>
            <div className={styles.questionContainer}>
              {isShowPronunciation() === true && (
                <p className={styles.pronunciationText}>
                  {`[${props.term.pronunciation}]`}
                </p>
              )}
              <p className={styles.questionText}>{question}</p>
              {isShowMemo() && (
                <p className={styles.memoText}>{props.term.memo}</p>
              )}
            </div>
            <div className={styles.answerContainer}>
              {isResultMode !== true && (
                <div className={styles.yourAnswerContainer}>
                  <p className={answerTitleTextClass}>Your answer</p>
                  <PlaceHolderTextField
                    hint={t("EnterCorrectAnswerText")}
                    lineStyle="single"
                    autoFocus={true}
                    handleTextChange={updateUserAnswer}
                    onKeyDownInput={(e) => {
                      if (e.key === "Enter") {
                        handleOnEnter();
                      }
                    }}
                  />
                </div>
              )}
              {isResultMode === true && (
                <div className={styles.answerResultContainer}>
                  {isAnswerResult === true && (
                    <p className={answerTitleTextClass}>
                      {t("CorrectAnswerMessage")}
                    </p>
                  )}
                  {isAnswerResult === false && (
                    <div className={styles.wrongAnswerContainer}>
                      <p className={styles.incorrectAnswerText}>
                        {t("IncorrectAnswerMessage")}
                      </p>
                      <button className={styles.answerIncorrectContainer}>
                        {userAnswer}
                      </button>
                      <p className={styles.correctAnswerText}>
                        {t("CorrectAnswer")}
                      </p>
                      <button className={styles.answerCorrectContainer}>
                        {answer}
                      </button>
                    </div>
                  )}
                  {isAnswerResult === false && (
                    <BigButton
                      title={t("Continue")}
                      style="normal"
                      handleClick={() => handleOnClickContinueButton()}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {audioSrc && (
          <AudioPlayer
            autoPlay={true}
            src={audioSrc}
            style={{ display: "none" }}
            onEnded={() => {
              setIsPlaying(false);
              setAudioSrc(undefined);
            }}
          />
        )}
      </motion.div>
    </>
  );
}
