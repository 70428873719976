import Term from "../term/Term";
import styles from "./Flashcards.module.css";
import SpeakButton from "../common/SpeakButton";
import { useEffect, useState } from "react";
import {
  getLearningQuestionTypeFromLocalStorage,
  isOnLearningTextToSpeechFromLocalStorage,
} from "../settings/learningSettings";
import { QuestionType } from "../settings/QuestionType";
import BigButton from "../common/BigButton";
import { useTranslation } from "react-i18next";
import { isShowMemo, isShowPronunciation } from "./learning";
import AudioPlayer from "react-h5-audio-player";
import { getVoiceFile, makeBase64String } from "../voice/voice";
import { fetchGroupLanguageCode } from "../groups/groupAPI";
import { auth } from "../../configurations/firebaseConfig";
import { motion } from "framer-motion";
import classNames from "classnames";

interface FlashcardsProps {
  term: Term;
  onCorrect: (term: Term) => void;
  onIncorrect: (term: Term) => void;
  onSpeakError: (error: string) => void;
}

export default function Flashcards(props: FlashcardsProps) {
  const { t } = useTranslation();

  const [audioSrc, setAudioSrc] = useState<string>();
  const [front, setFront] = useState<"front" | "back">("front");
  const randomChoice = Math.random() < 0.5 ? "term" : "definition";
  const [currentQuestion, setCurrentQuestion] = useState<"term" | "definition">(
    getCurrentQuestionType()
  );

  const [question, setQuestion] = useState("");
  const [questionUpdateCount, setQuestionUpdateCount] = useState(0);
  const [currentGroup, setCurrentGroup] = useState("");
  const [currentGroupUpdateCount, setCurrentGroupUpdateCount] = useState(0);
  const [speakCount, setSpeakCount] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    reload();
  }, [props.term]);

  useEffect(() => {
    setCurrentGroupUpdateCount(currentGroupUpdateCount + 1);
  }, [currentGroup]);

  useEffect(() => {
    setQuestionUpdateCount(questionUpdateCount + 1);
  }, [question]);

  useEffect(() => {
    if (
      speakCount === 0 &&
      currentGroupUpdateCount > 0 &&
      questionUpdateCount > 0 &&
      auth.currentUser !== null
    ) {
      if (isOnLearningTextToSpeechFromLocalStorage() === true) {
        speak();
      }
    }
  }, [question, currentGroup]);

  useEffect(() => {
    switch (front) {
      case "front":
        setQuestion(
          currentQuestion === "term" ? props.term.term : props.term.definition
        );
        setCurrentGroup(currentQuestion === "term" ? props.term.group : "");
        break;
      case "back":
        setQuestion(
          currentQuestion === "definition"
            ? props.term.term
            : props.term.definition
        );
        setCurrentGroup(
          currentQuestion === "definition" ? props.term.group : ""
        );
        break;
    }
  }, [currentQuestion, front, props.term]);

  function reload() {
    setSpeakCount(0);
    updateCurrentQuestionType();
  }

  function getCurrentQuestionType(): "term" | "definition" {
    switch (getLearningQuestionTypeFromLocalStorage()) {
      case QuestionType.termFirst:
        return "term";
      case QuestionType.definitionFirst:
        return "definition";
      case QuestionType.random:
        return randomChoice;
    }
  }

  function updateCurrentQuestionType() {
    switch (getLearningQuestionTypeFromLocalStorage()) {
      case QuestionType.termFirst:
        setCurrentQuestion("term");
        break;
      case QuestionType.definitionFirst:
        setCurrentQuestion("definition");
        break;
      case QuestionType.random:
        setCurrentQuestion(randomChoice);
        break;
    }
  }

  function handleOnClickContainer() {
    setFront(front === "front" ? "back" : "front");
  }

  function handleOnClickSpeakButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    speak();
  }

  function handleOnClickKnowButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setFront("front");
    props.onCorrect(props.term);
  }

  function handleOnClickStillLearningButton(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    e.stopPropagation();
    setFront("front");
    props.onIncorrect(props.term);
  }

  async function speak() {
    setSpeakCount(speakCount + 1);
    setIsPlaying(true);
    try {
      const languageCode =
        currentGroup.length !== 0
          ? await fetchGroupLanguageCode(currentGroup)
          : "";
      const audio = await getVoiceFile(question, languageCode);
      setAudioSrc(makeBase64String(audio));
    } catch (error) {
      if (error instanceof Error) {
        props.onSpeakError(error.message);
      }
    }
  }

  return (
    <>
      <motion.div
        className={styles.containerForMotion}
        key={props.term.term}
        initial={{ x: 100, opacity: 0 }}
        animate={{
          rotateX: front === "front" ? 0 : 180,
          x: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
        style={{
          transformStyle: "preserve-3d",
        }}
      >
        <div
          className={classNames(styles.container, {
            [styles.flipped]: front === "back",
          })}
          onClick={() => handleOnClickContainer()}
        >
          <div className={styles.topContainer}>
            <SpeakButton
              fontSize="small"
              isPlaying={isPlaying}
              onClick={handleOnClickSpeakButton}
            />
          </div>
          <div className={styles.contentsContainer}>
            {front === "front" && (
              <div className={styles.questionContainer}>
                {isShowPronunciation(
                  props.term.pronunciation,
                  front,
                  currentQuestion
                ) === true && (
                  <p className={styles.pronunciationText}>
                    {`[${props.term.pronunciation}]`}
                  </p>
                )}
                <p className={styles.questionText}>{question}</p>
                {isShowMemo(props.term.memo, front) && (
                  <p className={styles.memoText}>{props.term.memo}</p>
                )}
              </div>
            )}
            {front === "back" && (
              <div className={styles.answerContainer}>
                {isShowPronunciation(
                  props.term.pronunciation,
                  front,
                  currentQuestion
                ) === true && (
                  <p className={styles.pronunciationText}>
                    {`[${props.term.pronunciation}]`}
                  </p>
                )}
                <p className={styles.questionText}>{question}</p>
                {isShowMemo(props.term.memo, front) && (
                  <p className={styles.memoText}>{props.term.memo}</p>
                )}
              </div>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <BigButton
              title={t("StillLearningButton")}
              style="clear"
              hoverColor="var(--level-two-color)"
              handleClick={handleOnClickStillLearningButton}
            />
            <BigButton
              title={t("KnowButton")}
              style="clear"
              hoverColor="var(--level-three-color)"
              handleClick={handleOnClickKnowButton}
            />
          </div>
          {audioSrc && (
            <AudioPlayer
              autoPlay={true}
              src={audioSrc}
              style={{ display: "none" }}
              onEnded={() => {
                setIsPlaying(false);
                setAudioSrc(undefined);
              }}
            />
          )}
        </div>
      </motion.div>
    </>
  );
}
