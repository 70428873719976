import { useEffect, useState } from "react";
import Term from "../term/Term";
import styles from "./LearningOptions.module.css";
import { LearningType } from "./LearningType";
import { Trans, useTranslation } from "react-i18next";
import GroupSelectScreen from "../groups/GroupSelectScreen";
import Group from "../groups/Group";
import {
  getLearningIntervalUnitCountFromLocalStorage,
  getLearningPronunciationPositionFromLocalStroage,
  getLearningQuestionTypeFromLocalStorage,
  getLearningSelectedGroupsFromLocalStorage,
  getLearningSelectedLevelFromLocalStroage,
  getLearningSortTypeFromLocalStorage,
  getSpellingQuizQuestionTypeFromLocalStorage,
  isOnLearningTextToSpeechFromLocalStorage,
  isOnLearningWrongAnswerNoteFromLocalStroage,
  setIsOnLearningTextToSpeechToLocalStorage,
  setIsOnLearningWrongAnswerNoteFromToLocalStroage,
  setLearningIntervalUnitCountToLocalStroage,
  setLearningPronunciationPositionToLocalStorage,
  setLearningQuestionTypeToLocalStorage,
  setSpellingQuizQuestionTypeToLocalStorage,
} from "../settings/learningSettings";
import { makeSelectedGroupsString } from "../groups/groups";
import {
  isIncludeLevel,
  makeSelectedLevelsString,
  makeSortTypeString,
  toggleTermLevel,
} from "../term/terms";
import DropDownMenu from "../common/DropDownMenu";
import BasicMenu from "../common/BasicMenu";
import { SortType } from "../settings/SortType";
import BigButton from "../common/BigButton";
import OnevocaCheckBoxContainer from "../common/OnevocaCheckBoxContainer";
import { TermLevel } from "../term/TermLevel";
import Divider from "../common/Divider";
import { QuestionType } from "../settings/QuestionType";
import { PronunciationPosition } from "../settings/PronunciationPosition";
import { Switch } from "@mui/material";
import MediumButton from "../common/MediumButton";
import CountPicker from "../common/CountPicker";
import { CountPickerStyle } from "../common/CountPickerStyle";
import { NO_LIMIT } from "./learning";

interface LearningOptionsProps {
  open: boolean;
  terms: Term[];
  type: LearningType | null;
  mode: "beforeLearning" | "inLearning";
  onUpdateSelectedGroups: (groups: Group[]) => void;
  onUpdateSelectedLevels: (levels: TermLevel[]) => void;
  onUpdateSortType: (sortType: SortType) => void;
  onClickStartButton: (learningType: LearningType) => void;
  onClickRestartButton?: () => void;
  onClose: () => void;
}

export default function LearningOptions(props: LearningOptionsProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openGroupSelect, setOpenGroupSelect] = useState(false);
  const [openSortSettingsMenu, setOpenSortSettingsMenu] = useState(false);
  const [openLevelSettingsMenu, setOpenLevelSettingsMenu] = useState(false);
  const [openQuestionTypeSettingsMenu, setOpenQuestionTypeSettingsMenu] =
    useState(false);
  const [
    openPronunciationPositionSettingsMenu,
    setOpenPronunciationPositionSettingsMenu,
  ] = useState(false);
  const [openIntervalUnitCountPicker, setOpenIntervalUnitCountPicker] =
    useState(false);

  const [selectedGroupsString, setSelectedGroupsString] = useState("");
  const [selectedLevelsString, setSelectedLevelsString] = useState("");
  const [sortTypeString, setSortTypeString] = useState("");
  const [questionTypeString, setQuestionTypeString] = useState("");
  const [pronunciationPositionString, setPronunciationPositionString] =
    useState("");
  const [intervalUnitCountString, setIntervalUnitCountString] = useState("");
  const [isOnTextToSpeech, setIsOnTextToSpeech] = useState(false);
  const [isOnWrongAnswerNote, setIsOnWrongAnswerNote] = useState(false);

  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = "hidden";
      updateSelectedGroupsString();
      updateSelectedLevelsString();
      updateSortTypeString();
      updateQuestionTypeString(getQuestionType());
      updatePronunciationPositionString();
      updateIntervalUnitCountString();
      updateIsOnTextToSpeech();
      updateIsOnWrongAnswerNote();
    }
  }, [props.open]);

  function getTitle(): string | null {
    if (props.mode === "inLearning") {
      return t("Options");
    }

    switch (props.type) {
      case LearningType.flashcards:
        return t("Flashcard");
      case LearningType.multiplechoice:
        return t("MultipleChoiceTitle");
      case LearningType.spellingQuiz:
        return t("SpellingQuiz");
    }
    return null;
  }

  function getQuestionType(): QuestionType {
    return props.type === LearningType.spellingQuiz
      ? getSpellingQuizQuestionTypeFromLocalStorage()
      : getLearningQuestionTypeFromLocalStorage();
  }

  function handleOnClickContainer() {
    props.onClose();
  }

  function handleOnClickOptionsContainer(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function handleOnClickSortButton(e: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(e.currentTarget);
    setOpenSortSettingsMenu(true);
  }

  function handleOnClickQuestionTypeButton(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(e.currentTarget);
    setOpenQuestionTypeSettingsMenu(true);
  }

  function handleOnClickPronunciationButton(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(e.currentTarget);
    setOpenPronunciationPositionSettingsMenu(true);
  }

  function handleOnClickIntervalUnitCountButton(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(e.currentTarget);
    setOpenIntervalUnitCountPicker(true);
  }

  function handleOnClickLevelButton(e: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(e.currentTarget);
    setOpenLevelSettingsMenu(true);
  }

  function handleOnClickStartButton() {
    if (props.terms.length === 0) {
      alert(t("LearningNoTermsMessage"));
    } else {
      props.onClickStartButton(props.type!);
    }
  }

  function handleOnCLickRestartButton() {
    props.onClose();
    if (props.onClickRestartButton) {
      props.onClickRestartButton();
    }
  }

  function updateSelectedGroupsString() {
    const string = makeSelectedGroupsString(
      getLearningSelectedGroupsFromLocalStorage()
    );
    setSelectedGroupsString(string);
  }

  function updateSelectedLevelsString() {
    const string = makeSelectedLevelsString(
      getLearningSelectedLevelFromLocalStroage(),
      "learning"
    );
    setSelectedLevelsString(string);
  }

  function updateSortTypeString() {
    const string = makeSortTypeString(getLearningSortTypeFromLocalStorage());
    setSortTypeString(string);
  }

  function updateQuestionTypeString(questionType: QuestionType) {
    switch (questionType) {
      case QuestionType.termFirst:
        setQuestionTypeString(t("WordFirst"));
        break;
      case QuestionType.definitionFirst:
        setQuestionTypeString(t("MeanFirst"));
        break;
      case QuestionType.random:
        setQuestionTypeString(t("LearningTypeRandom"));
        break;
    }
  }

  function updatePronunciationPositionString() {
    const position = getLearningPronunciationPositionFromLocalStroage();
    switch (position) {
      case PronunciationPosition.onTerm:
        setPronunciationPositionString(t("PronOnQuestion"));
        break;
      case PronunciationPosition.onDefinition:
        setPronunciationPositionString(t("PronOnAnswer"));
        break;
    }
  }

  function updateIntervalUnitCountString() {
    const count = getLearningIntervalUnitCountFromLocalStorage();
    let string = "";
    if (count == NO_LIMIT) {
      string = t("LearningIntervalUnitCountAllType");
    } else {
      string = t("LearningIntervalUnitCountValueType", { count });
    }
    setIntervalUnitCountString(string);
  }

  function updateIsOnTextToSpeech() {
    setIsOnTextToSpeech(isOnLearningTextToSpeechFromLocalStorage());
  }

  function updateIsOnWrongAnswerNote() {
    setIsOnWrongAnswerNote(isOnLearningWrongAnswerNoteFromLocalStroage());
  }

  function updateTermLevel(checked: boolean, level: TermLevel) {
    const newLevels = toggleTermLevel(
      checked,
      level,
      getLearningSelectedLevelFromLocalStroage()
    );
    props.onUpdateSelectedLevels(newLevels);
    updateSelectedLevelsString();
  }

  function updateIntervalUnitCount(count: number) {
    setLearningIntervalUnitCountToLocalStroage(count);
    updateIntervalUnitCountString();
  }

  if (props.open === false) {
    return null;
  }

  return (
    <>
      <div className={styles.stack}>
        <div
          className={styles.container}
          onClick={() => handleOnClickContainer()}
        >
          <div
            className={styles.optionsContainer}
            onClick={handleOnClickOptionsContainer}
          >
            <div className={styles.contentsContainer}>
              <p className={styles.learningTitleText}>{getTitle()}</p>
              {props.mode === "beforeLearning" && (
                <div className={styles.learningGroupSettingsContainer}>
                  <p className={styles.settingsTitleText}>
                    {t("GroupSelectToLearn")}
                  </p>
                  <div
                    className={styles.groupSettingsValueContainer}
                    onClick={() => {
                      setOpenGroupSelect(true);
                    }}
                  >
                    <p className={styles.settingsValueText}>
                      {selectedGroupsString}
                    </p>
                    <img
                      className={styles.dropDownImage}
                      src="iconImgAllBack.png"
                    />
                  </div>
                </div>
              )}
              {props.mode === "beforeLearning" && <Divider />}
              {props.mode === "beforeLearning" && (
                <div className={styles.learningTypeContainer}>
                  <p className={styles.settingsTitleText}>
                    {t("QuestionType")}
                  </p>
                  <DropDownMenu
                    value={questionTypeString}
                    handleOnClick={handleOnClickQuestionTypeButton}
                  />
                </div>
              )}
              {props.mode === "beforeLearning" && (
                <div className={styles.learningLevelSettingsContainer}>
                  <p className={styles.settingsTitleText}>
                    {t("QuestionLevel")}
                  </p>
                  <div
                    className={styles.levelSettingsValueContainer}
                    onClick={handleOnClickLevelButton}
                  >
                    <p className={styles.settingsValueText}>
                      {selectedLevelsString}
                    </p>
                    <img
                      className={styles.dropDownImage}
                      src="iconImgAllBack.png"
                    />
                  </div>
                </div>
              )}
              {props.mode === "beforeLearning" && (
                <div className={styles.learningSortSettingsContainer}>
                  <p className={styles.settingsTitleText}>
                    {t("LearningOrder")}
                  </p>
                  <DropDownMenu
                    value={sortTypeString}
                    handleOnClick={handleOnClickSortButton}
                  />
                </div>
              )}
              <Divider />
              {props.mode === "inLearning" && (
                <div className={styles.learningPronunciationPositionContainer}>
                  <p className={styles.settingsTitleText}>
                    {t("PronPosition")}
                  </p>
                  <DropDownMenu
                    value={pronunciationPositionString}
                    handleOnClick={handleOnClickPronunciationButton}
                  />
                </div>
              )}
              {props.mode === "beforeLearning" && (
                <div className={styles.learningIntervalUnitCountContainer}>
                  <p className={styles.settingsTitleText}>
                    {t("LearningIntervalUnitCountSettingTitle")}
                  </p>
                  <DropDownMenu
                    value={intervalUnitCountString}
                    handleOnClick={handleOnClickIntervalUnitCountButton}
                  />
                </div>
              )}
              <div className={styles.learningTextToSpeechContainer}>
                <p className={styles.settingsTitleText}>
                  {t("LearningAutoSoundSettingTitle")}
                </p>
                <Switch
                  checked={isOnTextToSpeech}
                  onChange={(_, checked) => {
                    setIsOnLearningTextToSpeechToLocalStorage(checked);
                    setIsOnTextToSpeech(checked);
                  }}
                />
              </div>
              {props.type !== LearningType.flashcards &&
                props.mode === "inLearning" && (
                  <div className={styles.multipleChoiceContainer}>
                    <Divider />
                    <div className={styles.learningWrongAnswerNoteContainer}>
                      <p className={styles.settingsTitleText}>
                        {t("LearningIncorrectViewTitle")}
                      </p>
                      <Switch
                        checked={isOnWrongAnswerNote}
                        onChange={(_, checked) => {
                          setIsOnLearningWrongAnswerNoteFromToLocalStroage(
                            checked
                          );
                          setIsOnWrongAnswerNote(checked);
                        }}
                      />
                    </div>
                  </div>
                )}
              <Divider />
              {props.mode === "beforeLearning" && (
                <div className={styles.buttonsContainer}>
                  <BigButton
                    title={t("Cancel")}
                    style="disable"
                    handleClick={() => props.onClose()}
                  />
                  <BigButton
                    title={t("Start")}
                    style="normal"
                    handleClick={() => handleOnClickStartButton()}
                  />
                </div>
              )}
              {props.mode === "inLearning" && (
                <div className={styles.inLearningButtonsContainer}>
                  <button
                    className={styles.restartButton}
                    onClick={() => handleOnCLickRestartButton()}
                  >
                    {t("LearningRestart")}
                  </button>
                  <div className={styles.inLearningButtonsRightContainer}>
                    <MediumButton
                      title={t("Continue")}
                      style="primary"
                      onClick={() => props.onClose()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <CountPicker
          open={openIntervalUnitCountPicker === true}
          defaultValue={getLearningIntervalUnitCountFromLocalStorage()}
          style={CountPickerStyle.learningIntervalUnitCount}
          onComplete={(count: number) => updateIntervalUnitCount(count)}
          onClose={() => {
            setOpenIntervalUnitCountPicker(false);
          }}
        />
        <BasicMenu
          isOpen={openSortSettingsMenu === true}
          anchorEl={anchorEl}
          buttons={[
            {
              title: t("Random"),
              handleOnClose: () => {
                setOpenSortSettingsMenu(false);
                props.onUpdateSortType(SortType.random);
                updateSortTypeString();
              },
            },
            {
              title: t("Latest"),
              handleOnClose: () => {
                setOpenSortSettingsMenu(false);
                props.onUpdateSortType(SortType.recent);
                updateSortTypeString();
              },
            },
            {
              title: t("Old"),
              handleOnClose: () => {
                setOpenSortSettingsMenu(false);
                props.onUpdateSortType(SortType.old);
                updateSortTypeString();
              },
            },
            {
              title: t("Alphabet"),
              handleOnClose: () => {
                setOpenSortSettingsMenu(false);
                props.onUpdateSortType(SortType.abc);
                updateSelectedLevelsString();
              },
            },
          ]}
          handleOnClose={() => {
            setOpenSortSettingsMenu(false);
          }}
        />
        <BasicMenu
          isOpen={openQuestionTypeSettingsMenu === true}
          anchorEl={anchorEl}
          buttons={[
            {
              title: t("WordFirst"),
              handleOnClose: () => {
                setOpenQuestionTypeSettingsMenu(false);
                if (props.type === LearningType.spellingQuiz) {
                  setSpellingQuizQuestionTypeToLocalStorage(
                    QuestionType.termFirst
                  );
                } else {
                  setLearningQuestionTypeToLocalStorage(QuestionType.termFirst);
                }
                updateQuestionTypeString(getQuestionType());
              },
            },
            {
              title: t("MeanFirst"),
              handleOnClose: () => {
                setOpenQuestionTypeSettingsMenu(false);
                if (props.type === LearningType.spellingQuiz) {
                  setSpellingQuizQuestionTypeToLocalStorage(
                    QuestionType.definitionFirst
                  );
                } else {
                  setLearningQuestionTypeToLocalStorage(
                    QuestionType.definitionFirst
                  );
                }
                updateQuestionTypeString(getQuestionType());
              },
            },
            {
              title: t("LearningTypeRandom"),
              handleOnClose: () => {
                setOpenQuestionTypeSettingsMenu(false);
                if (props.type === LearningType.spellingQuiz) {
                  setSpellingQuizQuestionTypeToLocalStorage(
                    QuestionType.random
                  );
                } else {
                  setLearningQuestionTypeToLocalStorage(QuestionType.random);
                }
                updateQuestionTypeString(getQuestionType());
              },
            },
          ]}
          handleOnClose={() => {
            setOpenQuestionTypeSettingsMenu(false);
          }}
        />
        <BasicMenu
          isOpen={openPronunciationPositionSettingsMenu === true}
          anchorEl={anchorEl}
          buttons={[
            {
              title: t("PronOnQuestion"),
              handleOnClose: () => {
                setOpenPronunciationPositionSettingsMenu(false);
                setLearningPronunciationPositionToLocalStorage(
                  PronunciationPosition.onTerm
                );
                updatePronunciationPositionString();
              },
            },
            {
              title: t("PronOnAnswer"),
              handleOnClose: () => {
                setOpenPronunciationPositionSettingsMenu(false);
                setLearningPronunciationPositionToLocalStorage(
                  PronunciationPosition.onDefinition
                );
                updatePronunciationPositionString();
              },
            },
          ]}
          handleOnClose={() => {
            setOpenPronunciationPositionSettingsMenu(false);
          }}
        />
        <OnevocaCheckBoxContainer
          open={openLevelSettingsMenu === true}
          anchorEl={anchorEl}
          needCheckOneOrMore={true}
          items={[
            {
              title: t("Difficult"),
              checked: isIncludeLevel(
                TermLevel.difficult,
                getLearningSelectedLevelFromLocalStroage()
              ),
              onChangeValue(checked) {
                updateTermLevel(checked, TermLevel.difficult);
              },
            },
            {
              title: t("Familiar"),
              checked: isIncludeLevel(
                TermLevel.familiar,
                getLearningSelectedLevelFromLocalStroage()
              ),
              onChangeValue(checked) {
                updateTermLevel(checked, TermLevel.familiar);
              },
            },
            {
              title: t("Perfect"),
              checked: isIncludeLevel(
                TermLevel.perfect,
                getLearningSelectedLevelFromLocalStroage()
              ),
              onChangeValue(checked) {
                updateTermLevel(checked, TermLevel.perfect);
              },
            },
          ]}
          onClose={() => {
            setOpenLevelSettingsMenu(false);
          }}
        />
        <GroupSelectScreen
          open={openGroupSelect === true}
          selectStyle="multiple"
          showAllGroup={true}
          showNoGroup={true}
          showGroupAddButton={false}
          handleOnSelectGroups={(groups: Group[]) => {
            props.onUpdateSelectedGroups(groups);
            updateSelectedGroupsString();
          }}
          handleOnClose={() => {
            setOpenGroupSelect(false);
          }}
        />
      </div>
    </>
  );
}
