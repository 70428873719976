import PortOne from "@portone/browser-sdk/v2";
import { Payment } from "./payment";
import { PurchaseProduct } from "./products";
import OnevocaServerError from "../common/OnevocaServerError";
import { getToken } from "../auth/AuthManager";
import getApiInstance from "../common/api";
import { auth } from "../../configurations/firebaseConfig";
import axios from "axios";

export async function refund(paymentId: string): Promise<void> {
  const parameters = {
    reason: "결제오류",
  };

  try {
    const apiInstance = axios.create({
      baseURL: "https://api.portone.io",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await apiInstance.post(
      `/payments/${paymentId}/cancel`,
      parameters
    );
    console.log(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function requestPurchase(
  product: PurchaseProduct,
  payment: Payment
): Promise<void> {
  try {
    const response = await PortOne.requestPayment({
      storeId: process.env.REACT_APP_PORT_ONE_STORE_ID ?? "",
      channelKey: payment.channerKey,
      paymentId: payment.paymentId,
      orderName: product.name,
      totalAmount: product.price,
      vatAmount: product.vat,
      currency: "CURRENCY_KRW",
      payMethod: "CARD",
      customer: {
        customerId: auth.currentUser?.providerData[0].uid,
        phoneNumber: payment.customer.phone,
        email: payment.customer.email,
      },
    });

    if (response === undefined) {
      return Promise.reject(new Error("No response"));
    }

    if (response.code !== undefined) {
      return Promise.reject(new Error(response.message));
    }

    return;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function verifyPurchase(
  paymentId: string,
  product: PurchaseProduct
): Promise<void> {
  const parameters = {
    paymentId: paymentId,
    price: product.price,
  };

  const token = await getToken();
  const response = await getApiInstance(token).post(
    "/payment/complete.php",
    parameters
  );

  const error = response.data.error;
  if (error !== undefined) {
    uploadPurchaseError(paymentId, "5000", error);
    return Promise.reject(new OnevocaServerError(error));
  } else {
    return;
  }
}

export async function uploadPurchaseError(
  paymentId: string,
  product: string,
  message: string
) {
  await getApiInstance().get("/user_buy_slot_error.php", {
    params: {
      uid: auth.currentUser?.providerData[0].uid,
      product: product,
      paymentId: paymentId,
      message: message,
    },
  });
}

export async function deliverSlot(paymentId: string): Promise<void> {
  try {
    const token = await getToken();
    const response = await getApiInstance(token).get("/user/purchaseSlot.php", {
      params: {
        product: "5000",
        orderNumber: paymentId,
      },
    });
    const result = response.data.result;
    console.log(response.data);
    if (result !== "true") {
      uploadPurchaseError(paymentId, "5000", result);
      return Promise.reject(new OnevocaServerError(result));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
