import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Term from "../term/Term";
import styles from "./LearningScreen.module.css";
import { LearningType } from "./LearningType";
import LoadingScreen from "../common/LoadingScreen";
import { useLocation } from "react-router-dom";
import MediumButton from "../common/MediumButton";
import { LinearProgress, Snackbar } from "@mui/material";
import LearningOptions from "./LearningOptions";
import Flashcards from "./Flashcards";
import {
  getLearningSelectedGroupsFromLocalStorage,
  getLearningSelectedLevelFromLocalStroage,
  getLearningSortTypeFromLocalStorage,
} from "../settings/learningSettings";
import { SortType } from "../settings/SortType";
import { shuffle } from "lodash";
import MultipleChoice from "./MultipleChoice";
import { getFilteredTermsWithOptions } from "../term/terms";
import Written from "./Written";
import { Status } from "../common/Status";
import { finishLearning } from "./learningAPI";
import LearningResult from "./LearningResult";
import LearningResultScreen from "./LearningResultScreen";
import { generateRandomSeed, seededShuffle } from "../common/numbers";
import { useTerms } from "../term/useTerms";
import {
  getCurrentCount,
  getPageSize,
  getTotalCount,
  isLastCard,
} from "./learning";
import { LearningMode } from "./LearningMode";

interface LearningScreenProps {
  learningType: LearningType;
  learningMode: LearningMode;
  historyId?: string;
}

export default function LearningScreen() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [terms, setTerms] = useTerms();
  const [filteredTerms, setFilteredTerms] = useState<Term[]>([]);
  const [learningTerms, setLearningTerms] = useState<Term[]>([]);
  const [learnedTerms, setLearnedTerms] = useState<Term[]>([]);
  const [correctTerms, setCorrectTerms] = useState<Term[]>([]);
  const [incorrectTerms, setIncorrectTerms] = useState<Term[]>([]);

  const [seed, setSeed] = useState(generateRandomSeed());
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const location = useLocation();
  const { learningType, learningMode: initialLearningMode } =
    location.state as LearningScreenProps;
  const [learningMode, setLearningMode] = useState(initialLearningMode);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [openLearningOptions, setOpenLearningOptions] = useState(false);
  const [isReviewCurrentSection, setIsReviewCurrentSection] =
    useState<boolean>();

  const [currentPage, setCurrentPage] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTerm, setCurrentTerm] = useState<Term>();
  const [progressRatio, setProgressRatio] = useState(0.0);
  const [finishCount, setFinishCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [termsUpdateResultStatus, setTermsUpdateResultStatus] = useState(
    Status.Ready
  );

  const [learningResult, setLearningResult] = useState<LearningResult>();

  useEffect(() => {}, [isUpdating]);

  useEffect(() => {
    makeFilteredTerms();
  }, [terms]);

  useEffect(() => {
    updateLearningTerms();
  }, [filteredTerms]);

  useEffect(() => {
    setIsUpdating(false);
  }, [currentTerm]);

  useEffect(() => {
    // if (
    //   currentPage === 0 ||
    //   isReviewCurrentSection === true ||
    //   learningResult !== undefined
    // ) {
    //   updateCard();
    //   updateRatio();
    // } else {
    //   nextCard();
    // }
    updateCard();
    updateRatio();
  }, [learningTerms]);

  useEffect(() => {
    updateCard();
    updateRatio();
  }, [currentIndex]);

  useEffect(() => {
    switch (termsUpdateResultStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        setFinishCount(finishCount + 1);
        setIsReviewCurrentSection(undefined);
        break;
    }
  }, [termsUpdateResultStatus]);

  useEffect(() => {
    if (correctTerms.length == 0 && incorrectTerms.length == 0) {
      return;
    }
    nextCard();
  }, [correctTerms, incorrectTerms]);

  useEffect(() => {
    if (currentPage !== 0) {
      updateLearningTerms();
    }
  }, [currentPage]);

  useEffect(() => {
    if (isReviewCurrentSection === true && finishCount !== 0) {
      reviewCurrentSection();
    } else if (isReviewCurrentSection === false && finishCount !== 0) {
      continueNextSection();
    }
  }, [isReviewCurrentSection]);

  useEffect(() => {}, [learnedTerms]);

  function getLearningTitle(): string {
    switch (learningType) {
      case LearningType.flashcards:
        return t("Flashcard");
      case LearningType.multiplechoice:
        return t("MultipleChoiceTitle");
      case LearningType.spellingQuiz:
        return t("SpellingQuiz");
    }
  }

  function handleOnClickOptionsButton() {
    setOpenLearningOptions(true);
  }

  function handleOnClickExitButton() {
    navigate("/");
  }

  function handleOnClickCorrectButton(term: Term) {
    learningTerms[currentIndex].isCorrectAnswer = true;
    insertAnsweredTerm(learningTerms[currentIndex]);
  }

  function handleOnClickIncorrectButton(term: Term) {
    learningTerms[currentIndex].isCorrectAnswer = false;
    insertAnsweredTerm(learningTerms[currentIndex]);
  }

  function handleOnClickLearningResultContinueButton() {
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setLearningResult(undefined);
    setCurrentPage(currentPage + 1);
  }

  function updateRatio() {
    const newCurrentCount = getCurrentCount(
      learningMode,
      currentPage,
      currentIndex,
      filteredTerms.length
    );
    setCurrentCount(newCurrentCount);

    const newTotalCount = getTotalCount(
      learningMode,
      currentPage,
      learningTerms.length
    );
    setTotalCount(newTotalCount);

    const newRatio = (currentCount / totalCount) * 100;
    setProgressRatio(newRatio);
  }

  function insertAnsweredTerm(term: Term) {
    if (term.isCorrectAnswer) {
      const newCorrectTerms = [...correctTerms];
      newCorrectTerms.push(term);
      setCorrectTerms(newCorrectTerms);
    } else {
      const newIncorrectTerms = [...incorrectTerms];
      newIncorrectTerms.push(term);
      setIncorrectTerms(newIncorrectTerms);
    }

    if (!learnedTerms.some((t) => t.id === term.id)) {
      const newLearnedTerms = [...learnedTerms];
      newLearnedTerms.push(term);
      setLearnedTerms(newLearnedTerms);
    } else {
      const newLearnedTerms = learnedTerms.map((t) =>
        t.id === term.id ? term : t
      );
      setLearnedTerms(newLearnedTerms);
      console.log("Updated learned term: " + term.term);
    }
  }

  function nextCard() {
    setIsUpdating(true);
    const nextIndex = currentIndex + 1;
    if (nextIndex >= learningTerms.length) {
      finish();
    } else {
      setCurrentIndex(nextIndex);
    }
  }

  function updateCard() {
    if (currentIndex < learningTerms.length) {
      setCurrentTerm(
        (learningTerms[currentIndex] = {
          ...learningTerms[currentIndex],
          index: currentIndex,
        })
      );
    }
  }

  function updateLearningTerms() {
    let newTerms = filteredTerms;
    switch (learningMode) {
      case LearningMode.normal:
        const first =
          currentPage === 0
            ? 0
            : currentPage * getPageSize(filteredTerms.length);
        const end = Math.min(
          first + getPageSize(filteredTerms.length),
          filteredTerms.length
        );
        newTerms = [...filteredTerms.slice(first, end)];
        if (getLearningSortTypeFromLocalStorage() === SortType.random) {
          newTerms = shuffle(newTerms);
        }
        setLearningTerms(newTerms);
        break;
      case LearningMode.restartOnlyIncorrectTerms:
      case LearningMode.restartFromLearningHistory:
        newTerms = shuffle(filteredTerms);
        setLearningTerms(newTerms);
        break;
    }
  }

  function finish() {
    if (correctTerms.length !== 0 || incorrectTerms.length !== 0) {
      updateTermsResult();
    }
  }

  async function updateTermsResult() {
    setTermsUpdateResultStatus(Status.Loading);
    try {
      const result = await finishLearning(
        correctTerms,
        incorrectTerms,
        learningType
      );
      result.isLastCards = isLastCard(
        learnedTerms.length,
        filteredTerms.length
      );
      result.learningMode = learningMode;
      result.learnedTerms = learnedTerms;
      setLearningResult(result);
      setTermsUpdateResultStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermsUpdateResultStatus(Status.Ready);
    }
  }

  function resetLearningValues() {
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setLearningTerms([]);
    setLearnedTerms([]);
    setCurrentIndex(0);
    setCurrentPage(0);
    setFilteredTerms([]);
    setLearningResult(undefined);
  }

  function restartLearn() {
    if (learningMode === LearningMode.restartOnlyIncorrectTerms) {
      setLearningMode(LearningMode.normal);
    }

    resetLearningValues();
    makeFilteredTerms();
  }

  function restartOnlyIncorrectTerms(terms: Term[]) {
    if (learningMode !== LearningMode.restartFromLearningHistory) {
      setLearningMode(LearningMode.restartOnlyIncorrectTerms);
    }

    resetLearningValues();
    setFilteredTerms(terms);
  }

  function makeFilteredTerms() {
    const newFilteredTerms = getFilteredTermsWithOptions(
      terms,
      seed,
      getLearningSortTypeFromLocalStorage(),
      getLearningSelectedGroupsFromLocalStorage(),
      getLearningSelectedLevelFromLocalStroage()
    );
    setFilteredTerms(newFilteredTerms);
  }

  function continueNextSection() {
    setLearningResult(undefined);
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setCurrentIndex(0);
    setCurrentPage(currentPage + 1);
  }

  function reviewCurrentSection() {
    setLearningResult(undefined);
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setCurrentIndex(0);
    updateLearningTerms();
    updateCard();
  }

  function reviewCurrentSectionOnlyIncorrectTerms(incorrectTerms: Term[]) {
    setLearningResult(undefined);
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setCurrentIndex(0);
    setLearningTerms(incorrectTerms);
    updateCard();
  }

  function makeAnswers(): Term[] {
    if (currentTerm === undefined) {
      return [];
    }

    const newTerms = getFilteredTermsWithOptions(
      learningTerms,
      undefined,
      SortType.random,
      getLearningSelectedGroupsFromLocalStorage()
    );
    const correctTermIds = correctTerms.map((term) => term.id);
    let filteredTerms = newTerms
      .filter((term) => !correctTermIds.includes(term.id))
      .filter((term) => term !== currentTerm)
      .slice(0, 3);
    filteredTerms.push(currentTerm);
    return shuffle(filteredTerms);
  }

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <div className={styles.learningTypeContainer}>
              <p className={styles.learningTypeText}>{getLearningTitle()}</p>
            </div>
            <div className={styles.groupsContainer}>
              <p className={styles.groupsText}>
                {`${currentCount} / ${totalCount}`}
              </p>
            </div>
            <div className={styles.buttonsContainer}>
              <MediumButton
                title={t("Options")}
                style="clear"
                onClick={() => handleOnClickOptionsButton()}
              />
              <MediumButton
                title={t("Quit")}
                style="clear"
                onClick={() => handleOnClickExitButton()}
              />
            </div>
          </div>
          {learningResult !== undefined && (
            <LearningResultScreen
              open={learningResult !== undefined}
              result={learningResult}
              onClickContinueButton={() => continueNextSection()}
              onClickRestartButton={() => restartLearn()}
              onClickRestartOnlyIncorrectButton={restartOnlyIncorrectTerms}
              onClickReviewCurrentSectionButton={() =>
                setIsReviewCurrentSection(true)
              }
              onClickReviewCurrentSectionOnlyIncorrectButton={
                reviewCurrentSectionOnlyIncorrectTerms
              }
            />
          )}
          {learningResult === undefined && (
            <div className={styles.learningContainer}>
              <div className={styles.progressContainer}>
                <LinearProgress
                  className={styles.progress}
                  variant="determinate"
                  value={progressRatio}
                  sx={{
                    backgroundColor: "var(--sub-light-gray-color)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "var(--level-three-color)",
                    },
                  }}
                />
              </div>
              {learningType === LearningType.flashcards &&
                currentTerm !== undefined &&
                isUpdating === false && (
                  <Flashcards
                    term={currentTerm}
                    onCorrect={handleOnClickCorrectButton}
                    onIncorrect={handleOnClickIncorrectButton}
                    onSpeakError={(error: string) => {
                      setToastMessage(error);
                    }}
                  />
                )}
              {learningType === LearningType.multiplechoice &&
                currentTerm !== undefined &&
                isUpdating === false && (
                  <MultipleChoice
                    term={currentTerm}
                    answers={makeAnswers()}
                    onCorrect={handleOnClickCorrectButton}
                    onIncorrect={handleOnClickIncorrectButton}
                    onSpeakError={(error: string) => {
                      setToastMessage(error);
                    }}
                  />
                )}
              {learningType === LearningType.spellingQuiz &&
                currentTerm !== undefined &&
                isUpdating === false && (
                  <Written
                    term={currentTerm}
                    onCorrect={handleOnClickCorrectButton}
                    onIncorrect={handleOnClickIncorrectButton}
                    onSpeakError={(error: string) => {
                      setToastMessage(error);
                    }}
                  />
                )}
            </div>
          )}
        </div>
        <LearningOptions
          open={openLearningOptions === true}
          terms={[]}
          type={learningType}
          mode="inLearning"
          onUpdateSelectedGroups={() => {}}
          onUpdateSelectedLevels={() => {}}
          onUpdateSortType={() => {}}
          onClickStartButton={() => {}}
          onClickRestartButton={() => restartLearn()}
          onClose={() => {
            document.body.style.overflow = "auto";
            setOpenLearningOptions(false);
          }}
        />
        <Snackbar
          open={toastMessage !== null}
          autoHideDuration={3000}
          onClose={() => {
            setToastMessage(null);
          }}
          message={toastMessage}
        />
        {isLoading === true && <LoadingScreen />}
      </div>
    </>
  );
}
