import {
  getCachedLanguageCodeFromIndexedDB,
  getCachedVoiceFromIndexedDB,
  getVoiceGenderFromLocalStroage,
  setCachedVoiceToIndexedDB,
} from "../settings/voiceSettings";
import { detactLanguageCode } from "./languageApi";
import { SupportedLanguages } from "./languages";
import { fetchAudio } from "./voiceApi";

export enum VoiceGender {
  male = "male",
  female = "female",
}

export async function getVoiceFile(
  text: string,
  languageCode: string
): Promise<string> {
  const cachedVoice = await getCachedVoiceFromIndexedDB(text, languageCode);
  if (cachedVoice !== null) {
    return cachedVoice;
  }

  try {
    let lang = "";
    if (languageCode.length === 0) {
      const cachedLangage = await getCachedLanguageCodeFromIndexedDB(text);
      if (cachedLangage !== null) {
        // console.log("use cached language");
      }
      lang =
        cachedLangage !== null ? cachedLangage : await detactLanguageCode(text);
    } else {
      lang = languageCode;
    }

    const audio = await fetchAudio(text, lang, getVoiceActor(lang));
    setCachedVoiceToIndexedDB(text, languageCode, audio);
    return audio;
  } catch (error) {
    return Promise.reject(error);
  }
}

export function makeBase64String(src: string): string {
  let str = "data:audio/wav;base64,";
  str += src;
  return str;
}

export function getVoiceActor(languageCode: string): string {
  const gender = getVoiceGenderFromLocalStroage();
  switch (languageCode) {
    case SupportedLanguages.en:
      switch (gender) {
        case VoiceGender.male:
          return "en-US-Wavenet-J";
        case VoiceGender.female:
          return "en-US-Wavenet-H";
      }
    case SupportedLanguages.da:
      switch (gender) {
        case VoiceGender.male:
          return "da-DK-Wavenet-A";
        case VoiceGender.female:
          return "da-DK-Wavenet-A";
      }
    case SupportedLanguages.nl:
      switch (gender) {
        case VoiceGender.male:
          return "nl-NL-Wavenet-B";
        case VoiceGender.female:
          return "nl-NL-Wavenet-A";
      }
    case SupportedLanguages.fr:
      switch (gender) {
        case VoiceGender.male:
          return "fr-FR-Wavenet-D";
        case VoiceGender.female:
          return "fr-FR-Wavenet-E";
      }
    case SupportedLanguages.de:
      switch (gender) {
        case VoiceGender.male:
          return "de-DE-Wavenet-E";
        case VoiceGender.female:
          return "de-DE-Wavenet-F";
      }
    case SupportedLanguages.it:
      switch (gender) {
        case VoiceGender.male:
          return "it-IT-Wavenet-B";
        case VoiceGender.female:
          return "it-IT-Wavenet-A";
      }
    case SupportedLanguages.ja:
      switch (gender) {
        case VoiceGender.male:
          return "ja-JP-Wavenet-D";
        case VoiceGender.female:
          return "ja-JP-Wavenet-B";
      }
    case SupportedLanguages.ko:
      switch (gender) {
        case VoiceGender.male:
          return "ko-KR-Wavenet-D";
        case VoiceGender.female:
          return "ko-KR-Wavenet-B";
      }
    case SupportedLanguages.nb:
      switch (gender) {
        case VoiceGender.male:
          return "nb-NO-Wavenet-D";
        case VoiceGender.female:
          return "nb-no-Wavenet-E";
      }
    case SupportedLanguages.pl:
      switch (gender) {
        case VoiceGender.male:
          return "pl-PL-Wavenet-C";
        case VoiceGender.female:
          return "pl-PL-Wavenet-E";
      }
    case SupportedLanguages.pt:
      switch (gender) {
        case VoiceGender.male:
          return "pt-PT-Wavenet-C";
        case VoiceGender.female:
          return "pt-PT-Wavenet-D";
      }
    case SupportedLanguages.ru:
      switch (gender) {
        case VoiceGender.male:
          return "ru-RU-Wavenet-D";
        case VoiceGender.female:
          return "ru-RU-Wavenet-E";
      }
    case SupportedLanguages.sk:
      switch (gender) {
        case VoiceGender.male:
          return "sk-SK-Wavenet-A";
        case VoiceGender.female:
          return "sk-SK-Wavenet-A";
      }
    case SupportedLanguages.es:
      switch (gender) {
        case VoiceGender.male:
          return "es-ES-Standard-A";
        case VoiceGender.female:
          return "es-ES-Standard-A";
      }
    case SupportedLanguages.sv:
      switch (gender) {
        case VoiceGender.male:
          return "sv-SE-Wavenet-A";
        case VoiceGender.female:
          return "sv-SE-Wavenet-A";
      }
    case SupportedLanguages.tr:
      switch (gender) {
        case VoiceGender.male:
          return "tr-TR-Wavenet-E";
        case VoiceGender.female:
          return "tr-TR-Wavenet-D";
      }
    case SupportedLanguages.uk:
      switch (gender) {
        case VoiceGender.male:
          return "uk-UA-Wavenet-A";
        case VoiceGender.female:
          return "uk-UA-Wavenet-A";
      }
    case SupportedLanguages.zh:
      switch (gender) {
        case VoiceGender.male:
          return "cmn-CN-Wavenet-C";
        case VoiceGender.female:
          return "cmn-CN-Wavenet-D";
      }
    case SupportedLanguages.ar:
      switch (gender) {
        case VoiceGender.male:
          return "ar-XA-Wavenet-C";
        case VoiceGender.female:
          return "ar-XA-Wavenet-A";
      }
    case SupportedLanguages.cs:
      switch (gender) {
        case VoiceGender.male:
          return "cs-CZ-Wavenet-A";
        case VoiceGender.female:
          return "cs-CZ-Wavenet-A";
      }
    case SupportedLanguages.hi:
      switch (gender) {
        case VoiceGender.male:
          return "hi-IN-Wavenet-C";
        case VoiceGender.female:
          return "hi-IN-Wavenet-D";
      }
    case SupportedLanguages.hu:
      switch (gender) {
        case VoiceGender.male:
          return "hu-HU-Wavenet-A";
        case VoiceGender.female:
          return "hu-HU-Wavenet-A";
      }
    case SupportedLanguages.id:
      switch (gender) {
        case VoiceGender.male:
          return "id-ID-Wavenet-C";
        case VoiceGender.female:
          return "id-ID-Wavenet-D";
      }
    case SupportedLanguages.vi:
      switch (gender) {
        case VoiceGender.male:
          return "vi-VN-Wavenet-C";
        case VoiceGender.female:
          return "vi-VN-Wavenet-D";
      }
    case SupportedLanguages.bn:
      switch (gender) {
        case VoiceGender.male:
          return "bn-IN-Wavenet-D";
        case VoiceGender.female:
          return "bn-IN-Wavenet-C";
      }
    case SupportedLanguages.fil:
      switch (gender) {
        case VoiceGender.male:
          return "fil-PH-Wavenet-D";
        case VoiceGender.female:
          return "fil-PH-Wavenet-B";
      }
    case SupportedLanguages.fi:
      switch (gender) {
        case VoiceGender.male:
          return "fi-FI-Standard-A";
        case VoiceGender.female:
          return "fi-FI-Standard-A";
      }
    case SupportedLanguages.el:
      switch (gender) {
        case VoiceGender.male:
          return "el-GR-Wavenet-A";
        case VoiceGender.female:
          return "el-GR-Wavenet-A";
      }
    case SupportedLanguages.gu:
      switch (gender) {
        case VoiceGender.male:
          return "gu-IN-Wavenet-B";
        case VoiceGender.female:
          return "gu-IN-Wavenet-A";
      }
    case SupportedLanguages.he:
      switch (gender) {
        case VoiceGender.male:
          return "he-IL-Wavenet-D";
        case VoiceGender.female:
          return "he-IL-Wavenet-C";
      }
    case SupportedLanguages.kn:
      switch (gender) {
        case VoiceGender.male:
          return "kn-IN-Wavenet-D";
        case VoiceGender.female:
          return "kn-IN-Wavenet-C";
      }
    case SupportedLanguages.ms:
      switch (gender) {
        case VoiceGender.male:
          return "ms-MY-Wavenet-D";
        case VoiceGender.female:
          return "ms-MY-Wavenet-C";
      }
    case SupportedLanguages.ml:
      switch (gender) {
        case VoiceGender.male:
          return "ml-IN-Wavenet-D";
        case VoiceGender.female:
          return "ml-IN-Wavenet-C";
      }
    case SupportedLanguages.mr:
      switch (gender) {
        case VoiceGender.male:
          return "mr-IN-Wavenet-B";
        case VoiceGender.female:
          return "mr-IN-Wavenet-C";
      }
    case SupportedLanguages.pa:
      switch (gender) {
        case VoiceGender.male:
          return "pa-IN-Wavenet-D";
        case VoiceGender.female:
          return "pa-IN-Wavenet-C";
      }
    case SupportedLanguages.ro:
      switch (gender) {
        case VoiceGender.male:
          return "ro-RO-Wavenet-A";
        case VoiceGender.female:
          return "ro-RO-Wavenet-A";
      }
    case SupportedLanguages.ta:
      switch (gender) {
        case VoiceGender.male:
          return "ta-IN-Wavenet-D";
        case VoiceGender.female:
          return "ta-IN-Wavenet-C";
      }
    default:
      throw new Error("음성을 지원하지 않는 언어입니다.");
  }
}
