import BigButton from "../common/BigButton";
import BusinessContainer from "../common/BusinessContainer";
import LoadingScreen from "../common/LoadingScreen";
import TopNavigation from "../common/TopNavigation";
import styles from "./SlotPurchaseScreen.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { privacyPolicyUrl, termsOfUseUrl } from "../common/contants";
import { deliverSlot, requestPurchase, verifyPurchase } from "./purchase";
import { product_slot_5000 } from "./products";
import { generateRandomOrderNumber } from "../common/numbers";
import { Status } from "../common/Status";
import { getEmailAdress, getUsername } from "../auth/AuthManager";

export default function SlotPurchaseScreen() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [purchaseRequestStatus, setPurchaseRequestStatus] = useState(
    Status.Ready
  );
  const [purchaseVerifyStatus, setPurchaseVerifyStatus] = useState(
    Status.Ready
  );
  const [slotDeliverStatus, setSlotDeliverStatus] = useState(Status.Ready);

  const [paymentId, setPaymentId] = useState<string | null>(null);

  const termsOfUse = t("TermsOfUse");
  const privacyPolicy = t("PrivacyPolicy");

  useEffect(() => {
    if (paymentId !== null && paymentId.length === 40) {
      purchase();
    }
  }, [paymentId]);

  useEffect(() => {
    switch (purchaseRequestStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        verifyPurchaseOnServer();
        break;
    }
  }, [purchaseRequestStatus]);

  useEffect(() => {
    switch (purchaseVerifyStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        deliver();
        break;
    }
  }, [purchaseVerifyStatus]);

  useEffect(() => {
    switch (slotDeliverStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        alert(t("SlotPurchaseSuccessMessage"));
        setPaymentId(null);
        navigate("/");
        break;
    }
  }, [slotDeliverStatus]);

  function moveToMain() {
    navigate("/");
  }

  function openTermsOfUsePage() {
    window.open(termsOfUseUrl);
  }

  function openPrivacyPolicyPage() {
    window.open(privacyPolicyUrl);
  }

  async function handleOnClickPurchaseButton() {
    startPurchaseFlow();
  }

  async function startPurchaseFlow() {
    setPaymentId(generateRandomOrderNumber(40));
  }

  async function purchase() {
    setPurchaseRequestStatus(Status.Loading);
    try {
      await requestPurchase(product_slot_5000, {
        paymentId: paymentId!,
        channerKey: "channel-key-ebe6cd18-4395-473b-b5a9-8457f576038a",
        customer: {
          name: getUsername(),
          phone: "010-3871-3553",
          email: await getEmailAdress(),
        },
      });
      setPurchaseRequestStatus(Status.Done);
    } catch (error) {
      setPurchaseRequestStatus(Status.Ready);
    }
  }

  async function verifyPurchaseOnServer() {
    if (paymentId === null) {
      alert(t("PaymentIdMissing"));
    }

    setPurchaseVerifyStatus(Status.Loading);
    try {
      await verifyPurchase(paymentId!, product_slot_5000);
      setPurchaseVerifyStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setPurchaseVerifyStatus(Status.Ready);
    }
  }

  async function deliver() {
    if (paymentId === null) {
      alert(t("PaymentIdMissing"));
    }

    setSlotDeliverStatus(Status.Loading);
    try {
      await deliverSlot(paymentId!);
      setSlotDeliverStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setSlotDeliverStatus(Status.Ready);
    }
  }

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container}>
          <TopNavigation
            handleClickToggleLeftBarButton={() => {}}
            handleClickOnevocaLogo={() => moveToMain()}
            showTab={false}
            showUserIcon={false}
            handleClickLogOutButton={() => {}}
            handleClickTab={() => {}}
            handleClickUserProfileIcon={() => {}}
          />
          <div className={styles.contentContainer}>
            <p className={styles.slotPurchaseTitleText}>
              {t("SlotExtendMessage")}
            </p>
            <p className={styles.slotPriceText}>₩9,900</p>
            <p className={styles.slotPurchaseSubDescriptionText}>
              {t("SlotExtendDetailMessage")}
            </p>
            <BigButton
              title={t("buynow")}
              style="normal"
              handleClick={() => handleOnClickPurchaseButton()}
            />
            <p className={styles.desciptionText}>
              <Trans
                i18nKey="PurchaseDescriptionMessage"
                values={{ termsOfUse, privacyPolicy }}
              >
                <span
                  className={styles.highlightText}
                  onClick={() => openTermsOfUsePage()}
                >
                  {termsOfUse}
                </span>
                <span
                  className={styles.highlightText}
                  onClick={() => openPrivacyPolicyPage()}
                >
                  {privacyPolicy}
                </span>
                <span
                  className={styles.highlightText}
                  onClick={() => openTermsOfUsePage()}
                >
                  {termsOfUse}
                </span>
              </Trans>
            </p>
          </div>
          <BusinessContainer />
        </div>
        {isLoading === true && <LoadingScreen />}
      </div>
    </>
  );
}
