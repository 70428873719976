import { PronunciationPosition } from "../settings/PronunciationPosition";
import {
  getLearningIntervalUnitCountFromLocalStorage,
  getLearningPronunciationPositionFromLocalStroage,
} from "../settings/learningSettings";
import { LearningMode } from "./LearningMode";

export const NO_LIMIT = 0;

export function getPageSize(termsCount: number) {
  const intervalUnitCount = getLearningIntervalUnitCountFromLocalStorage();
  if (intervalUnitCount == NO_LIMIT) {
    return termsCount;
  } else {
    return intervalUnitCount;
  }
}

export function isLastCard(
  learnedTermsCount: number,
  totalTermsCount: number
): boolean {
  return learnedTermsCount >= totalTermsCount;
}

export function getCurrentCount(
  learningMode: LearningMode,
  currentPage: number,
  currentIndex: number,
  totalTermsCount: number
): number {
  switch (learningMode) {
    case LearningMode.normal:
      return currentPage * getPageSize(totalTermsCount) + currentIndex + 1;
    case LearningMode.restartOnlyIncorrectTerms:
      return currentIndex + 1;
    case LearningMode.restartFromLearningHistory:
      return currentIndex + 1;
  }
}

export function getTotalCount(
  learningMode: LearningMode,
  currentPage: number,
  learningTermsCount: number
): number {
  switch (learningMode) {
    case LearningMode.normal:
      const intervalUnitCount = getLearningIntervalUnitCountFromLocalStorage();
      if (intervalUnitCount == NO_LIMIT) {
        return learningTermsCount;
      } else {
        const totalCount = currentPage * intervalUnitCount + learningTermsCount;
        return totalCount;
      }
    case LearningMode.restartOnlyIncorrectTerms:
      return learningTermsCount;
    case LearningMode.restartFromLearningHistory:
      return learningTermsCount;
  }
}

export function isShowMemo(value: string, front: "front" | "back"): boolean {
  return value.length !== 0 && front !== "front";
}

export function isShowPronunciation(
  value: string,
  front: "front" | "back",
  firstQuestion: "term" | "definition"
): boolean {
  if (value.length === 0) {
    return false;
  }

  switch (firstQuestion) {
    case "term":
      if (
        getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onTerm
      ) {
        if (front === "front") {
          return true;
        }
      } else {
        if (front === "back") {
          return true;
        }
      }
      break;
    case "definition":
      if (
        getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onTerm
      ) {
        if (front === "back") {
          return true;
        }
      } else {
        if (front === "front") {
          return true;
        }
      }
      break;
  }
  return false;
}
