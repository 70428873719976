import Term from "../term/Term";
import { LearningMode } from "./LearningMode";

export default class LearningResult {
  terms: Term[];
  learnedTerms: Term[] = [];
  isLastCards: boolean = true;
  learningMode: LearningMode = LearningMode.normal;

  constructor(terms: Term[]) {
    this.terms = terms;
  }
}
